<template>
    <v-card
        v-if="item"
        tile
        class="schedule-member-cell theme-hover-bg pa-3"
        :to="{ name: 'schedule.lesson', params: { uuid: this.item.uuid } }"
    >
        <div class="text-truncate">
            <span class="text--primary caption font-weight-bold">{{ title }}</span>
        </div>
        <div class="subtitle text-truncate">
            <w-icon
                :value="guestsIcon"
                size="12"
                left
            />
            <span>{{ guests }}</span>
        </div>
        <div class="subtitle text-truncate">
            <w-icon
                :value="locationIcon"
                size="13"
                left
            />
            <span>{{ location.title }}</span>
        </div>
        <div class="text-right text-uppercase font-weight-bold mt-2" style="font-size: 8px">
            {{ lessonType }}
        </div>
    </v-card>
    <div v-else>&nbsp;</div>
</template>

<script>
import lessonDetailsPropertiesMixin from './mixins/lessonDetailsPropertiesMixin'
import { memberFullName } from '@/components/Member/utils'

export default {
    name: 'ScheduleLessonCell',
    mixins: [
        lessonDetailsPropertiesMixin
    ],
    props: {
        item: {
            type: Object,
            default: undefined
        }
    },
    computed: {
        guestsIcon() {
            return this.isMemberTeacher
                ? 'GROUP'
                : 'TEACHER'
        },
        guests() {
            return this.isMemberTeacher
                ? this.groups
                : memberFullName(this.teacher, 'short')
        }
    }
}
</script>

<style lang="scss">
.schedule-member-cell {
    display: inline-grid;
    width: 100%;

    .subtitle {
        font-size: 0.65rem !important;
        line-height: 1rem;
    }
}
</style>
