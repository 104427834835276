import _ from 'lodash'

export default {
    computed: {
        isMemberTeacher() {
            return this.$auth.isTeacher()
        },
        begin() {
            return _.get(this.item, 'begin', null)
        },
        end() {
            return _.get(this.item, 'end', null)
        },
        cover() {
            return _.get(this.item, 'cover.src', null)
        },
        course() {
            return _.get(this.item, 'course.title', 'unknown')
        },
        title() {
            return _.get(this.item, 'course.title')
        },
        lessonType() {
            return _.get(this.item, 'lesson_type.title', 'unknown')
        },
        locationIcon() {
            return _.get(this.item, 'location.uuid') === 'online'
                ? 'ONLINE'
                : 'LOCATION'
        },
        location() {
            return _.get(this.item, 'location', {})
        },
        teacher() {
            return _.get(this.item, 'teacher', {})
        },
        department() {
            return _.get(this.item, 'course.department.title', 'unknown')
        },
        groups() {
            const groups = _.get(this.item, 'groups', [])

            return groups.map(o => o.title)
                .join(', ')
        }
    }
}
